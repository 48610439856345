type BaseUrl = { url: string; label: string };
type WithDescription = BaseUrl & { description: string };
type WithIcon = BaseUrl & { icon: string };

// All Pages
export const marketingPages = {
  features: {
    url: '/features',
    label: 'Features',
  },
  featureRoutePlanning: {
    url: '/features/route-planning',
    label: 'Route Planning',
    description: 'Plan your next shipment with all the information at hand',
  },
  featurePortFinder: {
    url: '/features/port-finder',
    label: 'Port Finder',
    description: 'Explore global ports in an interactive map format',
  },
  featureTracking: {
    url: '/features/shipment-tracking',
    label: 'Shipment Tracking',
    description: 'Track shipping containers and air cargo in real-time',
  },
  featureCarriers: {
    url: '/features/carriers',
    label: 'Carrier directory',
    description: 'Find the right carrier for your shipping needs',
  },
  featureSchedules: {
    url: '/features/schedules',
    label: 'Shipping Schedules',
    description: 'Reliable and accurate schedules for every carrier',
  },
  plans: {
    url: '/plans',
    label: 'Plans & Pricing',
  },
  releaseNotes: {
    url: '/release-notes',
    label: 'Release Notes',
  },
  sources: {
    url: '/sources',
    label: 'Data Partners',
  },
  about: {
    url: '/about',
    label: 'About',
  },
  contact: {
    url: '/contact',
    label: 'Contact Us',
  },
  getQuote: {
    url: '/get-a-quote',
    label: 'Shipping enquiry',
  },
  careers: {
    url: '/about#careers',
    label: 'Careers',
  },
  privacy: {
    url: '/privacy',
    label: 'Privacy Policy',
  },
  terms: {
    url: '/terms-of-use',
    label: 'Terms of Use',
  },
  ports: {
    url: '/ports',
    label: 'Seaports & Airports',
  },
  carriers: {
    url: '/carriers',
    label: 'Airlines & Shipping Lines',
  },
  blog: {
    url: '/blog',
    label: 'Blog',
    description: 'Latest industry news and company updates from the team at Fluent Cargo',
  },
  news: {
    label: 'Resources',
    url: '/resources', // Doesn't actually exist
  },
  glossary: {
    url: '/glossary',
    label: 'Industry Glossary',
  },
  press: {
    url: '/press',
    label: 'Press & News',
    description: 'Discover relevant shipping and logistics news, industry updates and company updates',
  },
  caseStudies: {
    url: '/case-studies',
    label: 'Case Studies',
    description: 'See how our customers optimize logistics and enhance shipping operations with Fluent Cargo',
  },
  faqs: {
    url: '/faqs',
    label: 'FAQs',
  },
  youtube: {
    url: 'https://www.youtube.com/channel/UCr5p5jqtuO5S39vdURqjPLg',
    label: 'YouTube',
    icon: 'youtube',
  },
  linkedin: {
    url: 'https://au.linkedin.com/company/fluentcargo',
    label: 'LinkedIn',
    icon: 'linkedin',
  },
};

interface FooterNav {
  product: BaseUrl[];
  tools: WithDescription[];
  resources: BaseUrl[];
  company: BaseUrl[];
  social: WithIcon[];
}

// Footer Nav Items
export const footerNav: FooterNav = {
  product: [
    { ...marketingPages.features },
    { ...marketingPages.plans },
    { ...marketingPages.releaseNotes },
    { ...marketingPages.sources },
  ],
  tools: [
    { ...marketingPages.featureRoutePlanning },
    { ...marketingPages.featureTracking },
    { ...marketingPages.featureSchedules },
    { ...marketingPages.featurePortFinder },
  ],
  resources: [
    { ...marketingPages.faqs },
    { ...marketingPages.blog },
    { ...marketingPages.press },
    { ...marketingPages.caseStudies },
    { ...marketingPages.youtube },
  ],
  company: [{ ...marketingPages.about }, { ...marketingPages.careers }, { ...marketingPages.contact }],
  social: [{ ...marketingPages.youtube }, { ...marketingPages.linkedin }],
};

interface HeaderNavItems {
  url: string;
  label: string;
  children?: WithDescription[];
  className?: string;
}

// Primary Nav Items
export const headerNav: HeaderNavItems[] = [
  { ...marketingPages.features, children: [...footerNav.tools] },
  { ...marketingPages.plans, children: [] },
  {
    ...marketingPages.news,
    children: [{ ...marketingPages.blog }, { ...marketingPages.press }, { ...marketingPages.caseStudies }],
    className: 'md:!w-[400px] md:grid-flow-row',
  },
];
